export function input(){
    if($('input[type="text"], input[type="number"], input[type="password"], input[type="email"], textarea, .custom-select').length > 0){
        $('input[type="text"], input[type="number"], input[type="password"], input[type="email"], textarea, .custom-select').each(function(){
            if($(this).val() != ''){
                $(this).parent('.form-group').find('label').addClass('fill');
            }
        });
    }

    $('.form-group').on('focus', 'input[type="text"], input[type="number"], input[type="password"], input[type="email"], textarea', function() {
        $(this).parent('.form-group').removeClass('has-error');
        $(this).parent('.form-group').find('label').addClass('fill');
    });

    $('.form-group').on('click', 'label, input[type="text"], input[type="number"], input[type="password"], input[type="email"], textarea', function() {
        $(this).parent('.form-group').find('label').addClass('fill');
        $(this).parent('.form-group').find('input').trigger('focus');
    });

    $('.form-group').on('blur', 'label, input[type="text"], input[type="number"], input[type="password"], input[type="email"], textarea', function() {
        if($(this).parent('.form-group').find('input').val() == ''){
            $(this).parent('.form-group').find('label').removeClass('fill');
        }
    });

    $('.form-group').on('focus', 'select', function() {
        $(this).parent('.form-group').removeClass('has-error');
    });

    $('.custom-select').on('change', function(){
        $(this).trigger('blur');
    });

    /* Autocomplete */

    $('input.autocomplete').on('keydown', _.debounce(function(){

        var request = $.ajax({
            url: $(this).attr('data-url'),
            method: "get",
            data: {api_token: window.api_token,  q: $(this).val(), name: $(this).attr('name')},
            dataType: 'json',
        });
        request.done(function( response ) {
            if(response.name != undefined && response.list.length > 0){
                var li = '';
                for(var i in response.list){
                    li += '<li>'+ response.list[i] +'</li>';
                }
                $('input.autocomplete[name="' + response.name + '"]').parents('.form-group').find('ul li').remove();
                $('input.autocomplete[name="' + response.name + '"]').after('<ul class="autocomplete" style="display:none;">' + li + '</ul>');
                $('input.autocomplete[name="' + response.name + '"]').parents('.form-group').find('ul').slideDown(300);
            }else{
                $('input.autocomplete[name="' + response.name + '"]').parents('.form-group').find('ul').slideUp(300, function(){ $(this).remove()});
            }
        });
    }, 500));

    $(document).on('click', 'ul.autocomplete li', function(){
        $(this).parents('.form-group').find('input').val($(this).text());
        $(this).parents('ul').slideUp(300, function(){ $(this).remove()});
    });

    $(document).on('click', function(){
        $('ul.autocomplete').slideUp(300, function(){ $(this).remove()});
    });
    /* Filters */

    $('form#filter-form input').on('blur', function(){
        if($(this).val() != ''){
            $(this).parents('form').submit();
        }
    });

    $('form#filter-form select').on('change', function(){
        if($(this).val() != ''){
            $(this).parents('form').submit();
        }
    });
}

export function run() {
    $(function () {
        $(document).on('submit', '#profile-form', function (e) {
            e.preventDefault();
            var request = $.ajax({
                url: $(this).attr('data-action'),
                method: "POST",
                data: 'api_token=' + window.api_token + '&' + $(this).serialize(),
                dataType: 'json',
            });

            request.done(function( response ) {
                $('#success-modal').modal('show');
            });

            request.fail(function( response ) {
               helper.errorHandle('#profile-form', response);
            });
        });
        $(document).on('click', '#back-profile', function (e) {
            $('#success-modal').modal('hide');
        });
    });
}

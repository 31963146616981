window._ = require('lodash');
require('bootstrap');
window.$ = require( "jquery" );
window.jQuery = require( "jquery" );

window.helper = require('../helper/modules');
window.profile = require('./users.js');
window.search = require('./search.js');
window.marketing_support = require('./marketing_support.js');

import {input} from '../helper/input.js';
import {header} from './header.js';

input();
header();

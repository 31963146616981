export function header(){
    /* Menu */
    $('.hamburger-menu .menu-icon').on('click', function(){
        $('.hamburger-menu > .menu').fadeIn(500);
        $('.hamburger-menu > .menu ul').animate({'right': 0}, 300);
        $(this).parent('li').find('.m-close').delay(500).fadeIn(500);
    });

    $('.hamburger-menu .m-close').on('click', function(){
        $(this).fadeOut(300);
        $('.hamburger-menu > .menu ul').delay(350).animate({'right': '-81vw'}, 300);
        $('.hamburger-menu > .menu').delay(350).fadeOut(500);
    });

    $('.hamburger-menu > .menu li.main a').on('click', function(){
        if(!$(this).parent('li').hasClass('open')){
            $(this).parent('li').addClass('open');
            $(this).parent('li').find('ul').slideDown(300);
        }else{
            $(this).parent('li').removeClass('open');
            $(this).parent('li').find('ul').slideUp(300);
        }
    });

    /* search */

    $('.secondary-menu li.search:not(.active) .search-icon').on('click', function(){
        $(this).parents('li').addClass('active');
        $(this).parents('li').find('.m-close').delay(200).fadeIn(200);
        if($(window).width() > 992){
            var width = (parseInt($('.header-row').outerWidth(true)) + 80) - parseInt($('.logo-col').outerWidth(true)) - parseInt($('.secondary-menu-col').outerWidth(true));
            $(this).parents('li').find('.result').css({left: 0 , width: '100%'});
        }else{
            var width = (parseInt($('.header-row').outerWidth(true)) + 30) - 125;

            $(this).parents('li').find('.result').css({left: -8 - (($(window).width() - (parseInt($('.header-row').outerWidth(true)) + 30)) / 2), width: $(window).width()});
        }
        $(this).parents('.search-box').animate({width: width, right: '44px'}, 200, function(){
            $(this).find('input').show().trigger('focus');
        });
    });

    $('.secondary-menu li.search .m-close').on('click', function(){
        $(this).fadeOut(200);
        $(this).parents('li').find('.result').hide();
        $(this).parents('li').find('input').hide();
        $(this).parents('li').find('.search-box').animate({ right: 10}, 100);
        $(this).parents('li').find('.search-box').animate({width: 44}, 200, function(){
            $(this).parents('li').removeClass('active');
        });
    });

    $('.secondary-menu li.search .search-box input').on('keyup',  _.debounce(function(event){
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if(keycode == '13'){
            window.location.href= $(this).data('search-url') + '?q=' + $(this).val();
            return;
        }

        var href = $('.secondary-menu li.search .search-box .result > a').attr('href').split('?');

        $('.secondary-menu li.search .search-box .result > a').attr('href', href[0] + '?q=' + $(this).val());
        $('.secondary-menu li.search .search-box .result > a span').html($(this).val());
        $('.secondary-menu li.search .search-box .result ul').html('');
        var request = $.ajax({
            url: $(this).attr('data-action'),
            method: "get",
            data: {api_token: window.api_token,  q: $(this).val()},
            dataType: 'json',
        });

        request.done(function( response ) {
            if(response.length > 0){
                for(var index in response){
                    $('.secondary-menu li.search .search-box .result ul').append(
                        `<li>
                            <a href="${response[index].url}">
                            ${response[index].title}
                            ${response[index].sub_title != '' ? `<small>${response[index].sub_title}</small>` : ``}
                            </a>
                        </li>`
                    );
                }
                $('.secondary-menu li.search .search-box .result').slideDown(500);
            }else{
                $('.secondary-menu li.search .search-box .result').slideUp(500);
            }
        });

        request.fail(function( response ) {
           helper.errorHandle('#profile-form', response);
           $('.secondary-menu li.search .search-box .result').slideUp(500);
        });
    }, 1000));
}

module.exports = {
    /* Error handle */
    errorHandle: (form, response) => {
        if(response.responseJSON.errors != undefined){
            for(var key in response.responseJSON.errors){
                var errors = response.responseJSON.errors[key];
                var input_group = $(form).find('[name="' + key + '"]').parents('.form-group');
                input_group.addClass('has-error');
                input_group.find('span.error').html(errors.join('<br>'));
            }
        }
    },

    youtubePlay: () => {
        $(document).on('click', '[data-toggle="youtube"]', function() {height
            var height = $(this).find('img').outerWidth() * 0.57;
            $(this).addClass('play').html('<iframe style="width: 100%; height: ' + parseInt(height) + 'px;" src="https://www.youtube.com/embed/' + $(this).data('key') + '?autoplay=1&controls=1&modestbranding=1&iv_load_policy=0&rel=0&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
        });
    }
}

export function run() {
    $(function () {
        $(document).on('click', '#search-page .mobil-filter-button', function () {
            $('#search-page form.filters').parent('.overlay').fadeIn(300);
            $('#search-page form.filters').animate({left: 0}, 500);
        });

        $(document).on('click', '#search-page form.filters h6 svg', function () {
            $('#search-page form.filters').animate({left: '-81vw'}, 300);
            $('#search-page form.filters').parent('.overlay').fadeOut(500);
        });

        $(document).on('click', '#search-page form.filters .categories a', function (e) {
            e.preventDefault();
            if(!$(this).parent('li').hasClass('open')){
                $(this).parent('li').find('ul').slideDown(300, function(){
                    $(this).parents('li').addClass('open');
                });
            }else{
                $(this).parent('li').find('ul').slideUp(300, function(){
                    $(this).parents('li').removeClass('open');
                });
            }
        });

        $(document).on('change', '#search-page #sort', function () {
            $('#search-page form.filters input[name="sort"]').val($(this).val());
            $('#search-page form.filters').trigger('submit');
        });

        $(document).on('change', '#search-page form.filters .categories input', function () {
            $(this).parents('form').trigger('submit');
        });
    });
}
